import axios from "axios-jsonp-pro";

window.Vue.prototype.$axios = axios.create({
  headers: { "Content-Type": "multipart/form-data" }
});

window.Vue.prototype.$axiosJson = axios.create({
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache"
  }
});
window.Vue.prototype.$axiosJsonp = axios.create({});
window.Vue.prototype.$numberFormat = function(number) {
  const intl = new Intl.NumberFormat('ru', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return intl ? intl.format(number).toString() : "";
};
