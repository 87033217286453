<template>
    <div class="filter">

        <!-- Табы c фильтрами -->
        <el-tabs v-model="typeFilter" v-show="!seoPage">

            <!-- Вкладка с особенностями -->
            <el-tab-pane label="Проблемы" name="symptoms">
                <ul class="filter--list">
                    <li class="filter--list-item" style="margin-bottom: 15px;" v-for="(item, ind) in symptoms" :key="'symptoms' + ind">
                        <input class="visually-hidden"
                               v-model="item.value"
                               :id="'feature' + ind"
                               @change="search"
                               type="checkbox">
                        <label :for="'feature' + ind">{{item.title}}</label>
                    </li>
                </ul>
            </el-tab-pane>

            <!-- Вкладка со специалистами -->
            <el-tab-pane label="Специальности" name="specialities">
                <ul class="filter--list">
                    <li class="filter--list-item" v-for="(item, ind) in specialities" :key="'specialities' + ind">
                        <input class="visually-hidden" :id="'spec' + ind"
                               v-model="item.value"
                               @change="search"
                               type="checkbox">
                        <label :for="'spec' + ind">{{item.title}}</label>
                    </li>
                </ul>
            </el-tab-pane>
        </el-tabs>
        <el-collapse v-model="showAdding">
            <el-collapse-item title="Дополнительные фильтры" name="1">
                <div class="filter--additional uk-grid-small" uk-grid>
                    <fieldset class="filter--fieldset uk-width-1-1 uk-width-4-12@s uk-width-2-12@m">
                        <legend>Возраст ребенка</legend>
                        <el-select v-model="filters.age"
                                   multiple
                                   @change="search"
                                   placeholder="Выберите">
                            <el-option
                                    v-for="item in ages"
                                    :key="item.id"
                                    :label="item.title"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </fieldset>
                    <fieldset class="filter--fieldset uk-width-1-1 uk-width-3-12@s uk-width-2-12@m">
                        <legend>Пол</legend>
                        <div>
                            <el-radio-group v-model="filters.sex" @change="search">
                                <el-radio-button label="М"></el-radio-button>
                                <el-radio-button label="Ж"></el-radio-button>
                            </el-radio-group>
                        </div>
                    </fieldset>
                    <fieldset class="filter--fieldset uk-width-1-1 uk-width-5-12@s uk-width-2-12@m">
                        <legend>Опыт работы</legend>
                        <el-select v-model="filters.experience"
                                   @change="search"
                                   placeholder="Выберите">
                            <el-option
                                    v-for="item in experiences"
                                    :key="item.id"
                                    :label="item.title"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </fieldset>
                    <fieldset class="filter--fieldset uk-width-1-1 uk-width-4-12@s uk-width-3-12@m">
                        <legend>Образование</legend>
                        <el-select
                                v-model="filters.education"
                                multiple
                                @change="search"
                                placeholder="Выберите">
                            <el-option
                                    v-for="item in educations"
                                    :key="item.id"
                                    :label="item.title"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </fieldset>
                    <fieldset class="filter--fieldset uk-width-1-1 uk-width-4-12@s uk-width-3-12@m">
                        <legend>Место работы</legend>
                        <el-select v-model="filters.job"
                                   multiple
                                   @change="search"
                                   placeholder="Выберите">
                            <el-option
                                    v-for="item in jobs"
                                    :key="item.id"
                                    :label="item.title"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </fieldset>
                    <fieldset class="filter--fieldset uk-width-1-1 uk-width-4-12@s uk-width-3-12@m">
                        <legend>График работы</legend>
                        <el-select v-model="filters.schedule"
                                   @change="search"
                                   multiple
                                   placeholder="Выберите">
                            <el-option
                                    v-for="item in schedules"
                                    :key="item.id"
                                    :label="item.title"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </fieldset>
                    <fieldset class="filter--fieldset uk-width-1-1 uk-width-4-12@s uk-width-3-12@m">
                        <legend>Город работы</legend>
                        <el-select v-model="filters.city"
                                   @change="search"
                                   :multiple="false"
                                   placeholder="Выберите">
                            <el-option
                                    v-for="item in cities"
                                    :key="item.id"
                                    :label="item.title"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </fieldset>
                    <div class="filter--fieldset uk-width-1-1 uk-width-4-12@s uk-width-2-12@m uk-flex uk-flex-bottom">
                        <el-button class="text-button margin-bottom-xs" type="text" @click="clear">
                          Сбросить
                          <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1.0002 9.00027C1.64844 9.86446 2.55913 10.4955 3.59596 10.7988C4.63278 11.1022 5.73997 11.0616 6.7518 10.6831C7.76362 10.3046 8.62564 9.60857 9.20883 8.69922C9.79202 7.78986 10.065 6.71608 9.98692 5.63861C9.90884 4.56115 9.4839 3.53794 8.77569 2.72217C8.06749 1.9064 7.11412 1.34194 6.0583 1.11328C5.00249 0.884628 3.90101 1.00408 2.91875 1.45375C1.93649 1.90342 1.12629 2.65913 0.609425 3.60775" stroke="#70586B" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M1 11V9H3" stroke="#70586B" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </el-button>
                    </div>
                </div>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
    import {TutorFilter} from "../../models/TutorFilter.js";
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "TutorFilter",
        props: ["spec", "symp"],
        data() {
            return {
                typeFilter: 'symptoms',
                specialities: [],
                symptoms: [],
                filters: null,
                seoPage: false,
                showAdding: []
            };
        },
        created() {
            this.onCreated();
        },
        methods: {
            ...mapActions({
                tutorSearch: "Site/tutorSearch"
            }),
            onCreated() {
                this.filters = new TutorFilter();
                this.specialitiesStore.forEach(item => {
                    const copiedItem = Object.assign({}, item);
                    copiedItem.value = false;
                    this.specialities.push(copiedItem);
                });
                this.symptomsStore.forEach(item => {
                    const copiedItem = Object.assign({}, item);
                    copiedItem.value = false;
                    this.symptoms.push(copiedItem);
                });
                if (this.spec) {
                    this.seoPage = true;
                    this.filters.specialities = [Number(this.spec)];
                    this.filters.typeFilter = 'specialities';
                    this.tutorSearch({filters: this.filters});
                }
                if (this.symp) {
                    this.seoPage = true;
                    this.filters.symptoms = [Number(this.symp)];
                    this.filters.typeFilter = 'symptoms';
                    this.tutorSearch({filters: this.filters});
                }
            },
            search() {
                this.filters.specialities = [];
                this.filters.symptoms = [];
                this.filters.typeFilter = this.typeFilter;
                if (!this.spec && !this.symp) {
                    if (this.typeFilter == 'symptoms') {
                        this.specialities.forEach(item => {
                            item.value = false;
                        });
                        this.symptoms.forEach(item => {
                            if (item.value) {
                                this.filters.symptoms.push(item.id);
                            }
                        });
                    } else {
                        this.symptoms.forEach(item => {
                            item.value = false;
                        });
                        this.specialities.forEach(item => {
                            if (item.value) {
                                this.filters.specialities.push(item.id);
                            }
                        });
                    }
                } else {
                    if (this.spec) {
                        this.filters.specialities = [Number(this.spec)];
                        this.filters.typeFilter = 'specialities';
                    }
                    if (this.symp) {
                        this.filters.symptoms = [Number(this.symp)];
                        this.filters.typeFilter = 'symptoms';
                    }
                }
                this.tutorSearch({filters: this.filters});

            },
            clear() {
                this.filters.clearAddFilters();
                this.search();
            }
        },
        computed: {
            ...mapGetters({
                specialitiesStore: 'Site/specialities',
                symptomsStore: 'Site/symptoms',
                ages: 'Site/ages',
                educations: 'Site/educations',
                jobs: 'Site/jobs',
                experiences: 'Site/experiences',
                schedules: 'Site/schedules',
                cities: 'Site/cities'
            })
        }
    }
</script>
