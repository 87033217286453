<template>
    <div class="cookie-layer uk-width-1-1">
        <div class="cookie-layer--inner wrapper" v-if="!isCookie">
            <h4 class="cookie-layer--title uk-margin-bottom">
                <slot name="attentiontitle"></slot>
            </h4>
            <div class="cookie-layer--content uk-grid-small" uk-grid>
                <div class="uk-width-1-1 uk-width-5-6@m">
                    <p class="cookie-layer--message">
                        <slot name="attentiontext"></slot>
                    </p>
                </div>
                <div class="uk-width-1-1 uk-width-1-6@m uk-text-center uk-text-left@m">
                    <button class="cookie-layer--btn btn" type="button" name="button" @click="setCookie">
                        <slot name="attentionbutton"></slot>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const COOKIE_NAME = 'tutor_attention';
    export default {
        name: "CookieAttention",
        data() {
            return {
                isCookie: false
            };
        },
        created() {
            this.isCookie = this.$cookies.get(COOKIE_NAME) === 'allow';
        },
        methods: {
            setCookie() {
                this.$cookies.set(COOKIE_NAME, 'allow');
                this.isCookie = true;
            }
        }
    }
</script>

<style scoped>

</style>