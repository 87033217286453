export class TutorFilter {
    constructor(data) {
        this.updateByData(data);
    }

    updateByData(data) {
        if (!data) {
            data = {
                typeFilter: 'symptoms',
                symptoms: [],
                specialities: [],
                age: [],
                education: [],
                job: [],
                experience: null,
                schedule: [],
                sex: null,
                city: null,
            };
        }
        this.typeFilter = data.typeFilter;
        this.symptoms = data.symptoms;
        this.specialities = data.specialities;
        this.age = data.age;
        this.education = data.education;
        this.job = data.job;
        this.experience = data.experience;
        this.schedule = data.schedule;
        this.sex = data.sex;
        this.city = data.city;
    }

    success(tutor) {
        let ok = false;
        let i;
        ok = this[this.typeFilter].length == 0  || !!tutor[this.typeFilter].find(item => {
            return this[this.typeFilter].includes(item.id);
        });
        
        if (ok && this.sex) {
            ok = this.sex == 'М' && tutor.sex == 'm' || this.sex == 'Ж' && tutor.sex == 'f';
        }

        if (ok && this.age) {
            for (i = 0; i < this.age.length; i++) {
                if (ok = tutor.ages && tutor.ages.includes && tutor.ages.includes(this.age[i])) {
                    break;
                }
            }
        }

        if (ok && this.education) {
            for (i = 0; i < this.education.length; i++) {
                if (ok = tutor.educations && tutor.educations.includes && tutor.educations.includes(this.education[i])) {
                    break;
                }
            }
        }

        if (ok && this.job) {
            for (i = 0; i < this.job.length; i++) {
                if (ok = tutor.jobs && tutor.jobs.includes && tutor.jobs.includes(this.job[i])) {
                    break;
                }
            }
        }

        if (ok && this.schedule) {
            for (i = 0; i < this.schedule.length; i++) {
                if (ok = tutor.schedules && tutor.schedules.includes && tutor.schedules.includes(this.schedule[i])) {
                    break;
                }
            }
        }

        if (ok && this.experience) {
            if (this[this.typeFilter].length > 0) {
                ok = !!tutor[this.typeFilter].filter(item => {
                    return this[this.typeFilter].includes(item.id);
                }).find(item => {
                    let specs = [];
                    if (this.typeFilter == 'symptoms') {
                        specs = tutor.specialities.filter(s => {
                            return item.specs.includes(s.id);
                        });
                    } else {
                        specs = [item];
                    }
                    return !!specs.find(s => {
                        return s.exp >= this.experience;
                    });
                });

            } else {
                ok = !!tutor.specialities.find(s => {
                    return s.exp >= this.experience;
                });
            }

        }

        if (ok && this.city) {
            ok = tutor.cities && tutor.cities.includes && tutor.cities.includes(this.city)
        }
        return ok;
    }

    clearAddFilters() {
        this.age = null;
        this.education = null;
        this.job = null;
        this.experience = null;
        this.schedule = null;
        this.sex = null;
        this.city = null;
    }
}