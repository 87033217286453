<template>
    <div>
        <a class="show-phone" @click="showPhone = true" v-if="!phone">Показать номер телефона</a>
        <p class="specialists-card--phone" v-if="phone">
            <b>Номер телефона: <span>{{ phoneYear(phone) }}</span></b>
        </p>
        <el-dialog
                title="Показать номер телефона"
                :visible.sync="showPhone"
                custom-class="modal"
                center>
            <p>Для просмотра телефона необходимо авторизоваться на сайте.</p>
            <p class="uk-margin-remove-bottom">Не зарегистрированы? <a href="/registration">Зарегистрироваться</a></p>
            <p slot="footer" class="dialog-footer uk-text-left">
                <a href="/login" class="btn btn__large">
                    Войти
                </a>
            </p>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "ShowPhone",
        props: ['phone'],
        data() {
            return {
                showPhone: false
            }
        },
        methods: {
            phoneYear(num) {
                const reg = /^(\d)(\d{3})(\d{2})(\d{2})(\d{2,})$/;
                return num.replace(reg, '+$1 $2 $3 $4 $5')
            }
        }
    }
</script>

<style scoped>

</style>
