import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";

import createPersistedState from "vuex-persistedstate";

import Site from "./modules/Site";

Vue.use(Vuex);

const debug = process.env.NODE_ENV === "development";

// const plugins = [createPersistedState({ storage: window.sessionStorage })];
const plugins = [createPersistedState({ setState: () => {} })];

if (debug) {
    plugins.push(createLogger());
}

export default new Vuex.Store({
    modules: {
        Site
    },
    strict: debug,
    plugins: plugins
});
