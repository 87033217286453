import Vue from "vue";
import ElementUI from 'element-ui';
import store from "./store";
import VueCookies from 'vue-cookies';

/* utils */
import CookieAttention from './utils/CookieAttention.vue';

/* Components */
import TutorWidget from "./components/tutor/TutorWidget.vue";
import ShowPhone from "./components/tutor/ShowPhone.vue";
import NewsList from "./components/news/NewsList.vue";

/* Directives */
import OnlyDigit from "./directives/OnlyDigit.js";

window.Vue = Vue;
require("./bootstrap");
import lang from 'element-ui/lib/locale/lang/ru-RU';
import locale from 'element-ui/lib/locale';
Vue.use(ElementUI, {
    locale: 'ru-RU'
});

locale.use(lang);
Vue.use(VueCookies);
Vue.$cookies.config(60 * 60 * 24 * 365)

Vue.directive("only-digit", OnlyDigit);

/* utils */
Vue.component('cookie-attention', CookieAttention);

/* Components*/
Vue.component('tutor-widget', TutorWidget);
Vue.component('news-list', NewsList);
Vue.component('show-phone', ShowPhone);

new Vue({
  el: "#app",
    store,
});
