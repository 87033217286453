<template>
    <section class="actual actual__news-page">
            <div class="actual--filter">
                <el-radio-group v-model="filters" @change="addfilters" :disabled="loading">
                    <el-radio-button :label="0" key="0">Все</el-radio-button>
                    <el-radio-button v-for="item in tags" :label="item.id" :key="item.id">{{item.title}}</el-radio-button>
                </el-radio-group>
            </div>
            <div class="actual--cards uk-grid-small uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m margin-bottom-l"
                 uk-grid>
                <a class="actual--card"
                         :href="item.url"
                         v-for="(item, index) in list"
                         :key="'news' + index">
                    <div class="actual--card-inner">
                        <div class="actual--card-header uk-flex uk-flex-between">
                            <p class="actual--date">{{ item.date }}</p>
                            <p class="actual--type">{{ item.tag }}</p>
                        </div>

                        <h3 class="actual--card-title">
                          {{ item.menutitle }}
                        </h3>
                    </div>
                </a>
                <div class="text-center" v-if="loading">
                    Загрузка...
                </div>
            </div>
            <div class="specialists-cards--more" v-if="isMore && !loading">
                <a class="specialists-cards--more-btn" @click="more">
                    Показать еще
                </a>
            </div>
    </section>
</template>

<script>
    export default {
        name: "NewsList",
        data() {
            return {
                filters: 0,
                tags: window.newsListWidget.tags,
                page: 1,
                count: 0,
                isMore: false,
                list: [],
                loading: false,
            };
        },
        created() {
            this.getData();
        },
        methods: {
            addfilters() {
                this.list = [];
                this.page = 1;
                this.getData();
            },
            setPage(page) {
                this.list = [];
                this.page = page;
                this.getData();
            },
            more() {
                this.page++;
                this.getData();
            },
            getData() {
                console.error(this.filters);
                let config = {
                    params: {
                        page: this.page,
                        tags: [this.filters]
                    }
                };
                this.loading = true;
                this.$axiosJson.get("/ajax/news/list-data", config).then(response => {
                    if (response && response.data) {
                        this.isMore = response.data.isMore;
                        this.count = response.data.count;
                        if (this.page > 1) {
                            this.list = this.list.concat(response.data.items);
                        } else {
                            this.list = response.data.items;
                        }
                    }
                    this.loading = false;
                });
            }
        }
    }
</script>
