<template>
    <div class="uk-width-1-1 uk-width-expand@m">
        <ul class="specialists-cards--list">
            <li class="specialists-cards--item"
                v-for="(item, ind) in itemsPage"
                :key="'item' + ind">
                <!-- Карточка специалиста -->
                <article class="specialists-card uk-card uk-card-default uk-grid-collapse uk-margin" uk-grid>
                    <a class="specialists-card--img uk-card-media-left uk-cover-container uk-width-1-1 uk-width-auto@s"
                       :href="item.href">
                        <img :src="item.image" :alt="item.fio" v-if="item.image">
                        <div class="specialists-card--no-photo" v-if="!item.image">
                            <svg width="58" height="41" viewBox="0 0 58 41" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect x="1" y="1" width="56" height="39" rx="3" stroke="#ECECEC" stroke-width="2"/>
                                <path d="M9 30.5L16 23.5L22.5 30" stroke="#ECECEC" stroke-width="2"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M27 23.5L39.5 11L48 19.5" stroke="#ECECEC" stroke-width="2"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                                <circle cx="16" cy="11" r="4" stroke="#ECECEC" stroke-width="2"/>
                            </svg>
                            <p>Нет фотографии</p>
                        </div>
                    </a>
                    <div class="uk-width-1-1 uk-width-expand@s">
                        <div class="specialists-card--body uk-card-body">
                            <div class="uk-flex uk-flex-baseline">
                                <a :href="item.href">
                                    <h3 class="specialists-card--title uk-card-title">
                                        {{ item.fio }}
                                    </h3>
                                </a>
                                <span class="specialists-card--age uk-margin-small-left">{{item.age}} {{formatYear(item.age)}}</span>
                            </div>

                            <div class="margin-bottom-s">
                                <span class="specialists-card-experience margin-bottom-s"
                                      v-for="(spec, indSpec) in item.specialities">
                                  {{spec.title}} - опыт <span
                                        v-if="spec.exp > 0">{{spec.exp}} {{formatYear(spec.exp)}}</span><span
                                        v-if="spec.exp == 0">до 1 года</span><span
                                        v-if="indSpec < item.specialities.length - 1">,</span>
                                </span>
                            </div>


                            <p class="specialists-card--description">
                                {{ item.desc }}
                            </p>

                            <div class="specialists-card--contacts">
                                <show-phone :phone="item.phone"></show-phone>
                            </div>
                        </div>
                    </div>
                </article>
            </li>
            <li v-if="itemsPage.length == 0">
                Для данных условий ничего не выбрано, попробуйте изменить фильтры.
            </li>
        </ul>

        <div class="specialists-cards--more" v-if="moreCount > 0">
            <a class="specialists-cards--more-btn" @click="showMore">
                Показать еще
            </a>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "TutorList",
        data() {
            return {
                page: 1,
                numpage: 15,
            };
        },
        computed: {
            ...mapGetters({
                tutorFilter: "Site/tutorFilter",
                tutors: "Site/tutors",
            }),
            items() {
                const result = [];
                for (let i = 0; i < this.tutors.length; i++) {
                    if (this.tutorFilter.success(this.tutors[i])) {
                        result.push(this.tutors[i]);
                    }
                }
                return result.sort((a, b) => (a.fio > b.fio) ? 1 : ((b.fio > a.fio) ? -1 : 0));
            },
            itemsPage() {
                return this.items.slice(0, this.page * this.numpage);
            },
            moreCount() {
                return this.items.length - this.itemsPage.length;
            }
        },
        watch: {
            tutorFilter() {
                this.page = 1;
            }
        },
        methods: {
            formatYear(num) {
                if (num % 10 == 1) {
                    return 'год';
                }
                if ([2, 3, 4].includes(num)) {
                    return 'года';
                }
                return 'лет';
            },
            phoneYear(num) {
                const reg = /^(\d)(\d{3})(\d{2})(\d{2})(\d{2,})$/;
                return num.replace(reg, '+$1 $2 $3 $4 $5')
            },
            showMore() {
                this.page++;
            }
        }
    }
</script>

<style scoped>

</style>
