import {TutorFilter} from "../../models/TutorFilter.js";

const stateDefault = {
    specialities: [],
    symptoms: [],
    tutors: [],
    cities: [],
    ages: [],
    educations: [],
    jobs: [],
    experiences: [],
    schedules: [],
    tutorFilter: new TutorFilter()
};

const state = JSON.parse(JSON.stringify(stateDefault));

const getters = {
    specialities(state) {
        return state.specialities;
    },
    symptoms(state) {
        return state.symptoms;
    },
    tutors(state) {
        return state.tutors;
    },
    tutorFilter(state) {
        return new TutorFilter(state.tutorFilter);
    },
    cities(state) {
        return state.cities;
    },
    ages(state) {
        return state.ages;
    },
    jobs(state) {
        return state.jobs;
    },
    schedules(state) {
        return state.schedules;
    },
    experiences(state) {
        return state.experiences;
    },
    educations(state) {
        return state.educations;
    }
};
const mutations = {
    setSpecialities(state, specialities) {
        state.specialities = specialities;
    },
    setSymptoms(state, symptoms) {
        state.symptoms = symptoms;
    },
    setTutors(state, tutors) {
        state.tutors = tutors;
    },
    setTutorFilter(state, filters) {
        state.tutorFilter = new TutorFilter(filters);
    },
    setAges(state, ages) {
        state.ages = ages;
    },
    setEducations(state, educations) {
        state.educations = educations;
    },
    setJobs(state, jobs) {
        state.jobs = jobs;
    },
    setExperiences(state, experiences) {
        state.experiences = experiences;
    },
    setSchedules(state, schedules) {
        state.schedules = schedules;
    },
    setCities(state, cities) {
        state.cities = cities;
    }
};
const actions = {
    tutorSearch(context, {filters}) {
        context.commit("setTutorFilter", filters);
    },
    tutorGetList(context) {
        return window.Vue.prototype.$axiosJson
            .get(
                '/ajax/tutor/tutors-list'
            ).then(response => {
                if (response.status === 200) {
                    if (response.data) {
                        if (response.data && response.data.errors) {
                            console.error(response.data.errors);
                        } else {
                            const specialities = [];
                            const symptoms = [];
                            const specialitiesIds = [];
                            const symptomsIds = [];
                            response.data.tutors.forEach(tutor => {
                                tutor.specialities.forEach(spec => {
                                    if (!specialitiesIds.includes(spec.id)) {
                                        specialitiesIds.push(spec.id);
                                        specialities.push(spec);
                                    }
                                });
                                tutor.symptoms.forEach(symp => {
                                    if (!symptomsIds.includes(symp.id)) {
                                        symptomsIds.push(symp.id);
                                        symptoms.push(symp);
                                    }
                                });
                            });
                            symptoms.sort(function compare( a, b ) {
                                if ( a.position < b.position ){
                                    return -1;
                                }
                                if ( a.position > b.position ){
                                    return 1;
                                }
                                return 0;
                            });
                            context.commit("setSpecialities", specialities);
                            context.commit("setSymptoms", symptoms);
                            context.commit("setTutors", response.data.tutors);
                            context.commit("setAges", response.data.ages);
                            context.commit("setEducations", response.data.educations);
                            context.commit("setJobs", response.data.jobs);
                            context.commit("setExperiences", response.data.experiences);
                            context.commit("setSchedules", response.data.schedules);
                            context.commit("setCities", response.data.cities);
                            return true;
                        }
                    }
                    return false;
                }

            });

    }
};



export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
