<template>
    <div>
        <!-- Раздел c фильтрами -->
        <TutorFilter v-if="!loading" :spec="spec" :symp="symp"></TutorFilter>

        <!-- Раздел со списком специалистов и баннером -->
        <section class="specialists-cards uk-flex-between" uk-grid ref="section">
            <TutorList v-if="!loading"></TutorList>
            <slot name="banner" ref="bannerSlot"></slot>
        </section>
    </div>
</template>

<script>

    import {mapActions} from "vuex";
    import TutorFilter from "./TutorFilter.vue";
    import TutorList from "./TutorList.vue";

    export default {
        name: "TutorWidget",
        props: ["spec", "symp"],
        components: {
            TutorFilter, TutorList
        },
        data() {
            return {
                loading: true
            };
        },
        created() {
            this.tutorGetList().then(response => {
                this.loading = false;
                setTimeout(() => {
                    const banners = this.$refs.section.getElementsByClassName("js-banners")[0];
                    banners.className = banners.className.replace(/hidden/g, "");
                    window.showContent();
                }, 500);
            });
        },
        methods: {
            ...mapActions({
                tutorGetList: 'Site/tutorGetList'
            })
        }
    }
</script>